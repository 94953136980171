/* eslint-disable @typescript-eslint/no-explicit-any */
type Procedure = (...args: unknown[]) => any;
/* eslint-enable @typescript-eslint/no-explicit-any */

interface AnimationFramed<F extends Procedure> {
    (this: ThisParameterType<F>, ...args: Parameters<F>): Promise<ReturnType<F>>;
    cancel: (reason?: unknown) => void;
}

export const noop = (): void => { /* Does nothing */ }

export const asyncNoop = async (): Promise<void> => { /* Does nothing, asynchronously */ };

export function animationFrame<F extends Procedure>(this: unknown, func: F, context: unknown = this): AnimationFramed<F> {
    let requestId: number;
    let promises: {
        resolve: (x: ReturnType<F>) => void;
        reject: (reason?: unknown) => void;
      }[] = [];

    const animationFramedFunction = function (this: ThisParameterType<F>, ...args: Parameters<F>): Promise<ReturnType<F>> {
        return new Promise<ReturnType<F>>((resolve, reject) => {
            if (requestId !== undefined)
                cancelAnimationFrame(requestId);

            requestId = requestAnimationFrame(function () {
                const result = func.apply(context, args);
                promises.forEach(({ resolve }) => resolve(result));
                promises = [];
            });

            promises.push({ resolve, reject });
        });
    }
    animationFramedFunction.cancel = function (reason?: unknown) {
        cancelAnimationFrame(requestId);
        promises.forEach(({ reject }) => reject(reason));
        promises = [];
    };

    return animationFramedFunction;
}
